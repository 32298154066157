import React from "react";

const Name = () => (
  <div className="flex">
    <h1
      className="text-5xl transform rotate-180 uppercase font-bold"
      style={{ writingMode: "vertical-lr" }}
    >
      Nathaniel
    </h1>

    <h1
      className="text-5xl transform rotate-180 uppercase font-bold"
      style={{ writingMode: "vertical-lr" }}
    >
      Noyd
    </h1>
  </div>
);

export default Name;
