import React from "react";
import Seo from "./Seo";
import Footer from "./Footer";

const Layout = (props) => (
  <body className="bg-white">
    <Seo />
    <main className="m-5 grid	grid-cols-1">{props.children}</main>
    <Footer />
  </body>
);

export default Layout;
